@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Spline+Sans:wght@300;400;500;600;700&display=swap')
@import "../css-variables/variables"

*
    padding: 0
    margin: 0
    box-sizing: border-box
    font-family: "Kaushan Script", cursive
img 
    width: 100px
    height: 100px

html 
    scroll-behavior: smooth

#root
    background: #000000
    color: white

    .app-layout
        background-image: linear-gradient(#000000b0, $orange-lowOpacity, $yellow-lowOpacity)
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center