@import url("https://fonts.googleapis.com/css2?family=Kavivanar&display=swap");
header {
  display: flex;
  flex-direction: column;
  width: 85%;
  justify-content: space-between;
  margin: 10px 0 0 0;
  align-self: center;
}
header .top {
  display: flex;
  padding: 0 15%;
  justify-content: space-between;
}
header .top h2 {
  font-size: 25px;
  background: -webkit-linear-gradient(#FF5B00, #FFAF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0 10px;
}
header .top .header-locations {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
header .top .header-locations .menuLink {
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
  color: white;
}
header .top .header-locations .circle {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: linear-gradient(to Left, rgb(255, 91, 0) 37%, rgb(255, 175, 0) 100%);
}
header .bottom {
  height: 1px;
  background: -webkit-linear-gradient(left, rgba(30, 87, 153, 0) 0%, #FF5B00 50%, #FF5B00 51%, rgba(225, 228, 226, 0) 99%, rgba(229, 229, 229, 0) 100%);
}

.mainView {
  display: flex;
  width: 85%;
  align-items: center;
  height: 100vh;
  justify-content: space-around;
}
.mainView .leftView {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10vh;
}
.mainView .leftView h1 {
  font-size: 60px;
  text-align: center;
}
.mainView .leftView h1 .aloha {
  background: -webkit-linear-gradient(#FF5B00, #FFAF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mainView .leftView h1 .alaska {
  color: #72C2E9;
}
.mainView .leftView .state-icons {
  display: flex;
  justify-content: space-between;
}
.mainView .leftView .state-icons img {
  width: 125px;
  height: 125px;
}
.mainView .leftView .state-icons .arrow {
  width: 50px;
  height: 50px;
  align-self: center;
}
.mainView .leftView .seeMenu {
  background: linear-gradient(to right, #FF5B00, #FFAF00);
  border-radius: 25px;
  padding: 2px;
  text-decoration: none;
}
.mainView .leftView .seeMenu .inside {
  background: rgba(0, 0, 0, 0.8392156863);
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
}
.mainView .leftView .seeMenu .inside h6 {
  font-size: 30px;
  text-decoration: none;
  background: -webkit-linear-gradient(#FF5B00, #FFAF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mainView .leftView .seeMenu .inside img {
  width: 40px;
  height: 40px;
}
.mainView .right-view {
  display: flex;
  flex-direction: column;
}
.mainView .right-view img {
  width: 550px;
  height: 550px;
  transform: scale(-1, 1) rotate(-15deg);
}
.mainView .right-view .shadow {
  width: 75%;
  height: 100px;
  align-self: center;
  border-radius: 50%;
  background: #000000;
  opacity: 1;
  -webkit-filter: blur(20px);
}

.divider {
  height: 1px;
  background: -webkit-linear-gradient(left, rgba(30, 87, 153, 0) 0%, #FF5B00 50%, #FF5B00 51%, rgba(225, 228, 226, 0) 99%, rgba(229, 229, 229, 0) 100%);
  width: 85%;
}

.mainView-divider {
  margin: 50px 0 0 0;
}

.menuView {
  display: flex;
  width: 85%;
  min-height: 100vh;
  flex-direction: column;
  gap: 100px;
  position: relative;
  padding-bottom: 50px;
}
.menuView .legal-container {
  position: absolute;
  bottom: 5px;
  font-size: 0.75em;
  width: 100%;
}
.menuView .legal-container p {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}
.menuView .category {
  color: white !important;
  width: 80%;
  margin: 35px 0;
  display: flex;
  justify-content: start;
  align-self: center;
  flex-direction: column;
}
.menuView .category .info-tag {
  margin: 15px 0;
  font-size: 18px;
  background: -webkit-linear-gradient(#FF5B00, #FFAF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuView .category .input-form {
  width: 100%;
  min-width: 375px;
}
.menuView .category input {
  color: #fff !important;
}
.menuView .category .label {
  color: #FF5B00;
}
.menuView .category .selectInput:hover {
  border-color: #FF5B00 !important;
}
.menuView .category .selectInput .MuiSelect-select {
  background: -webkit-linear-gradient(#FF5B00, #FFAF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menuView .category .selectInput .MuiOutlinedInput-notchedOutline {
  border-color: #FF5B00;
  color: #fff !important;
  width: 100% !important;
}
.menuView .category .selectInput .MuiOutlinedInput-notchedOutline:hover {
  border-color: #FF5B00 !important;
}
.menuView .category .selectInput .MuiOutlinedInput-notchedOutline:focus {
  border: 2px solid white !important;
}
.menuView .category .selectInput .MuiSvgIcon-root {
  fill: #FF5B00;
}
.menuView .menuItems-container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  gap: 25px;
  margin: 0 0 10vh 0;
  justify-content: center;
  align-self: center;
}
.menuView .menuItems-container .item {
  height: 275px;
  width: 250px;
  border: 2px solid #FF5B00;
  border-top-left-radius: 38px;
  border-top-right-radius: 38px;
  border-bottom-left-radius: 85px;
  border-bottom-right-radius: 85px;
  text-align: center;
}
.menuView .menuItems-container .item .bg-opacity {
  width: 100%;
  height: 100%;
  border-top-left-radius: 38px;
  border-top-right-radius: 38px;
  border-bottom-left-radius: 85px;
  border-bottom-right-radius: 85px;
  background: rgba(0, 0, 0, 0.4980392157) 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 10px;
  align-items: center;
}
.menuView .menuItems-container .item .bg-opacity .text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menuView .menuItems-container .item .bg-opacity .text-container ul {
  background: -webkit-linear-gradient(#FF5B00, #FFAF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 10px 0 0 20px;
  color: #FF5B00;
}
.menuView .menuItems-container .item .bg-opacity .text-container ul li {
  text-align: left;
  font-size: 12px !important;
  font-family: Arial, Helvetica, sans-serif;
}
.menuView .menuItems-container .item .bg-opacity .top {
  height: 10%;
  width: 100%;
  padding: 2px 10px;
}
.menuView .menuItems-container .item .bg-opacity .top .divider {
  width: 100% !important;
}
.menuView .menuItems-container .item .bg-opacity .middle {
  display: flex;
  align-items: center;
  white-space: pre-wrap;
}
.menuView .menuItems-container .item .bg-opacity .bottom {
  height: 15%;
  width: 100%;
}
.menuView .menuItems-container .item .bg-opacity .bottom .divider {
  width: 100% !important;
}
.menuView .menuItems-container .item .bg-opacity .rotating {
  width: 50px;
  height: 50px;
  justify-self: center;
  align-self: center;
}
.menuView .menuItems-container .item .bg-opacity h4 {
  background: -webkit-linear-gradient(#FF5B00, #FFAF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
  font-size: 18px;
}
.menuView .menuItems-container .item .bg-opacity p {
  background: -webkit-linear-gradient(#FF5B00, #FFAF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

.MuiPaper-root {
  background: rgba(0, 0, 0, 0.8470588235) !important;
  border: 2px solid #FF5B00;
}
.MuiPaper-root li {
  background: -webkit-linear-gradient(#FF5B00, #FFAF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 1px solid rgba(255, 174, 0, 0.2980392157);
}
.MuiPaper-root li:last-child {
  border-bottom: none !important;
}

.contactView {
  height: 100vh;
  width: 85%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 200px 0;
}
.contactView .contact-info-container {
  display: flex;
  align-self: center;
  width: 80%;
  flex-direction: column;
  gap: 90px;
  margin: 0 0 0 25%;
}
.contactView .contact-info-container .info-container {
  width: 100%;
  display: flex;
  gap: 10px;
}
.contactView .contact-info-container .info-container .img-container {
  width: 75px;
  height: 75px;
  border-radius: 50px;
  position: relative;
  border: 1px solid #FF5B00;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.337254902);
}
.contactView .contact-info-container .info-container .img-container img {
  width: 40px;
  height: 40px;
}
.contactView .contact-info-container .info-container .contactInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.contactView .contact-info-container .info-container .contactInfo h4, .contactView .contact-info-container .info-container .contactInfo h6 {
  font-family: "Kavivanar", cursive !important;
  font-weight: 400;
  font-style: normal;
}
.contactView .contact-info-container .info-container .contactInfo h4 {
  font-size: 22px;
}
.contactView .contact-info-container .info-container .contactInfo h6 {
  font-size: 16px;
}
.contactView .contact-info-container .top {
  display: flex;
}
.contactView .contact-info-container .bottom {
  display: flex;
}
.contactView .map-container {
  width: 70%;
  height: 350px;
  padding: 20px;
  border: 2px solid #FF5B00;
  margin: 50px 0;
  border-radius: 20px;
  align-self: center;
}
.contactView .map-container iframe {
  border-radius: 20px;
}

.plugIn {
  text-align: center;
  font-family: "Kavivanar", cursive !important;
  opacity: 50%;
}

@media screen and (max-width: 1024px) {
  .right-view {
    display: none !important;
  }
  .map-container {
    width: 100% !important;
  }
  .contact-info-container {
    margin: 0 !important;
    width: 95% !important;
  }
}
@media screen and (max-width: 1440px) {
  .contact-info-container {
    margin: 0 0 0 20% !important;
  }
}
@media screen and (max-width: 1250px) {
  .contact-info-container {
    margin: 0 0 0 18% !important;
    gap: 20px !important;
  }
  .contact-info-container .top {
    flex-direction: column;
    gap: 20px !important;
  }
  .contact-info-container .bottom {
    flex-direction: column;
    gap: 20px !important;
  }
}
@media screen and (max-width: 700px) {
  .menuItems-container .item {
    height: 300px !important;
    width: 300px !important;
  }
  .menuItems-container .item .bg-opacity h4 {
    font-size: 20px !important;
  }
  header h2 {
    font-size: 20px !important;
  }
  .info-container .img-container {
    width: 50px !important;
    height: 50px !important;
  }
  .info-container .img-container img {
    width: 25px !important;
    height: 25px !important;
  }
}
@media screen and (max-width: 500px) {
  header {
    width: 100% !important;
  }
  header .top {
    justify-content: center !important;
  }
  header h2 {
    font-size: 18px !important;
  }
  header .header-locations {
    display: none !important;
  }
  .category .input-form {
    min-width: 250px !important;
  }
  .seeMenu {
    max-width: 275px !important;
  }
  .leftView h1 {
    font-size: 40px !important;
  }
  .leftView .state-icons img {
    width: 100px !important;
    height: 100px !important;
  }
  .leftView .state-icons .arrow {
    height: 35px !important;
    width: 35px !important;
  }
}
@media screen and (max-width: 500px) {
  .contactView .contact-info-container {
    margin: 0 !important;
  }
  .contactView .contact-info-container .info-container {
    width: 100% !important;
  }
  .contactView .contact-info-container .info-container h6 {
    font-size: 18px !important;
  }
  .contactView .contact-info-container .info-container h4 {
    font-size: 18px !important;
  }
  .contactView .contact-info-container .address-container h6 {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 320px) {
  header h2 {
    font-size: 10px !important;
  }
}/*# sourceMappingURL=styles.css.map */