$orange: #FF5B00
$yellow: #FFAF00


$orange-dark: #ff590069
$yellow-dark: #FFAF0069


$orange-lowOpacity: #ff59004c
$yellow-lowOpacity: #ffae004c



$large-laptop-break-point: 1440px
$med-laptop-break-point: 1250px
$laptop-break-point: 1024px
$tablet-break-point: 800px
$large-phone-break-point: 700px
$med-phone-break-point: 500px
$small-phone-break-point: 320px

