@import "../../css-variables/variables"
@import url('https://fonts.googleapis.com/css2?family=Kavivanar&display=swap')

header
    display: flex
    flex-direction: column
    width: 85%
    justify-content: space-between
    margin: 10px 0 0 0
    align-self: center

    .top
        display: flex
        padding: 0 15%
        justify-content: space-between

        h2 
            font-size: 25px
            background: -webkit-linear-gradient($orange, $yellow)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            padding: 0 10px

        .header-locations
            display: flex
            justify-content: center
            align-items: center
            gap: 15px

            .menuLink 
                font-size: 15px
                cursor: pointer
                text-decoration: none
                color: white

            .circle
                height: 5px
                width: 5px
                border-radius: 50%
                background: linear-gradient(to Left, rgba(255,91,0,1) 37%, rgba(255,175,0,1) 100%)
    .bottom
        height: 1px
        background: -webkit-linear-gradient(left, rgba(30,87,153,0) 0%,$orange 50%,$orange 51%,rgba(225,228,226,0) 99%,rgba(229,229,229,0) 100%)



.mainView 
    display: flex
    width: 85%
    align-items: center
    height: 100vh
    justify-content: space-around

    .leftView 
        display: flex
        justify-content: center
        flex-direction: column
        gap: 10vh

        h1 
            font-size: 60px
            text-align: center

            .aloha 
                background: -webkit-linear-gradient($orange, $yellow)
                -webkit-background-clip: text
                -webkit-text-fill-color: transparent
            .alaska
                color: #72C2E9

        .state-icons
            display: flex
            justify-content: space-between

            img 
                width: 125px
                height: 125px
            
            .arrow
                width: 50px
                height: 50px
                align-self: center
            


        .seeMenu
            background: linear-gradient(to right, $orange, $yellow)
            border-radius: 25px
            padding: 2px
            text-decoration: none

            .inside
                background: #000000d6
                border-radius: 25px
                display: flex
                justify-content: space-around
                align-items: center
                padding: 5px

                h6 
                    font-size: 30px
                    text-decoration: none
                    background: -webkit-linear-gradient($orange, $yellow)
                    -webkit-background-clip: text
                    -webkit-text-fill-color: transparent

                img
                    width: 40px
                    height: 40px
    
    .right-view
        display: flex
        flex-direction: column
        img
            width: 550px
            height: 550px
            transform: scale(-1, 1) rotate(-15deg)
        
        .shadow
            width: 75%
            height: 100px
            align-self: center
            border-radius: 50%
            background: #000000
            opacity: 1
            -webkit-filter: blur(20px)


.divider
        height: 1px
        background: -webkit-linear-gradient(left, rgba(30,87,153,0) 0%,$orange 50%,$orange 51%,rgba(225,228,226,0) 99%,rgba(229,229,229,0) 100%)
        width: 85%

.mainView-divider
    margin: 50px 0 0 0

.menuView
    display: flex
    width: 85%
    min-height: 100vh
    flex-direction: column
    gap: 100px
    position: relative
    padding-bottom: 50px

    .legal-container
        position: absolute
        bottom: 5px
        font-size: .75em
        width: 100%

        p 
            font-family: Arial, Helvetica, sans-serif
            text-align: center


    .category
        color: white !important
        width: 80%
        margin: 35px 0
        display: flex
        justify-content: start
        align-self: center
        flex-direction: column

        .info-tag
            margin: 15px 0
            font-size: 18px
            background: -webkit-linear-gradient($orange, $yellow)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent


        .input-form
            width: 100%
            min-width: 375px

        input 
            color: #fff !important

        .label
            color: $orange

        .selectInput 
            &:hover
                border-color: $orange !important

            .MuiSelect-select
                background: -webkit-linear-gradient($orange, $yellow)
                -webkit-background-clip: text
                -webkit-text-fill-color: transparent

            .MuiOutlinedInput-notchedOutline
                border-color: $orange
                color: #fff !important
                width: 100% !important

                &:hover
                    border-color: $orange !important


                &:focus 
                    border: 2px solid white !important

            .MuiSvgIcon-root
                fill: $orange

    .menuItems-container
        display: flex
        flex-wrap: wrap
        width: 80%
        gap: 25px
        margin: 0 0 10vh 0
        justify-content: center
        align-self: center

        .item
            height: 275px
            width: 250px
            border: 2px solid $orange
            border-top-left-radius: 38px
            border-top-right-radius: 38px
            border-bottom-left-radius: 85px
            border-bottom-right-radius: 85px
            text-align: center

            .bg-opacity
                width: 100%
                height: 100%
                border-top-left-radius: 38px
                border-top-right-radius: 38px
                border-bottom-left-radius: 85px
                border-bottom-right-radius: 85px
                background: #0000007f000
                display: flex
                justify-content: space-between
                flex-direction: column
                padding: 0 10px
                align-items: center

                .text-container
                    display: flex
                    flex-direction: column
                    justify-content: space-between

                    ul
                        background: -webkit-linear-gradient($orange, $yellow)
                        -webkit-background-clip: text
                        -webkit-text-fill-color: transparent
                        margin: 10px 0 0 20px
                        color: $orange

                        li 
                            text-align: left
                            font-size: 12px !important
                            font-family: Arial, Helvetica, sans-serif

                .top
                    height: 10%
                    width: 100%
                    padding: 2px 10px
                    .divider
                        width: 100% !important
                .middle
                    display: flex
                    align-items: center
                    white-space: pre-wrap
                    
                .bottom
                    height: 15%
                    width: 100%
                    .divider
                        width: 100% !important

                

                .rotating 
                    width: 50px
                    height: 50px
                    justify-self: center
                    align-self: center

                h4 
                    background: -webkit-linear-gradient($orange, $yellow)
                    -webkit-background-clip: text
                    -webkit-text-fill-color: transparent
                    text-transform: capitalize
                    font-size: 18px

                p
                    background: -webkit-linear-gradient($orange, $yellow)
                    -webkit-background-clip: text
                    -webkit-text-fill-color: transparent
                    font-size: 18px
                    font-family: Arial, Helvetica, sans-serif

.MuiPaper-root
    background: #000000d8 !important
    border: 2px solid $orange

    li 
        background: -webkit-linear-gradient($orange, $yellow)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        border-bottom: 1px solid $yellow-lowOpacity

        &:last-child            
           border-bottom: none !important
            
    

.contactView
    height: 100vh
    width: 85%
    display: flex
    justify-content: center
    flex-direction: column
    margin: 0 0 200px 0



    .contact-info-container
        display: flex
        align-self: center
        width: 80%
        flex-direction: column
        gap: 90px
        margin: 0 0 0 25%

        .info-container
            width: 100%
            display: flex
            gap: 10px

            .img-container
                width: 75px
                height: 75px
                border-radius: 50px
                position: relative
                border: 1px solid $orange
                display: flex
                justify-content: center
                align-items: center
                background: #00000056

                img 
                    width: 40px
                    height: 40px

            .contactInfo
                display: flex
                flex-direction: column
                justify-content: space-around
                
                h4, h6 
                    font-family: "Kavivanar", cursive !important
                    font-weight: 400
                    font-style: normal

                h4 
                    font-size: 22px

                h6 
                    font-size: 16px

        .top
            display: flex

        .bottom
            display: flex

    

    .map-container
        width: 70%
        height: 350px
        padding: 20px
        border: 2px solid $orange
        margin: 50px 0
        border-radius: 20px
        align-self: center

        iframe
            border-radius: 20px

.plugIn
    text-align: center
    font-family: "Kavivanar", cursive !important
    opacity: 50%





@media screen and (max-width: $laptop-break-point) 
    .right-view
        display: none !important
    .map-container
        width: 100% !important

    .contact-info-container
        margin: 0 !important
        width: 95% !important

@media screen and (max-width: $large-laptop-break-point) 
    .contact-info-container 
        margin: 0 0 0 20% !important

@media screen and (max-width: $med-laptop-break-point) 
    .contact-info-container 
        margin: 0 0 0 18% !important
        gap: 20px !important

        .top
            flex-direction: column
            gap: 20px !important
        .bottom
            flex-direction: column
            gap: 20px !important

@media screen and (max-width: $large-phone-break-point) 
    .menuItems-container
        .item 
            height: 300px !important
            width: 300px !important
            .bg-opacity
                h4 
                    font-size: 20px !important

    header 
        h2 
            font-size: 20px !important

    .info-container
        .img-container
            width: 50px !important
            height: 50px !important

            img 
                width: 25px !important
                height: 25px !important


@media screen and (max-width: $med-phone-break-point) 
    header 
        width: 100% !important
        .top 
            justify-content: center !important
        h2 
            font-size: 18px !important

        .header-locations
            display: none !important
    .category
        .input-form
            min-width: 250px !important
    .seeMenu
        max-width: 275px !important

    .leftView
        h1 
            font-size: 40px !important
        .state-icons
            img 
                width: 100px !important
                height: 100px !important
            .arrow
                height: 35px !important
                width: 35px !important

@media screen and (max-width: $med-phone-break-point) 
    .contactView
        .contact-info-container 
            margin: 0 !important
            .info-container
                width: 100% !important

                h6
                    font-size: 18px !important
                h4 
                    font-size: 18px !important
            .address-container
                h6 
                    font-size: 14px !important

            

@media screen and (max-width: $small-phone-break-point) 
    header 
        h2 
            font-size: 10px !important