@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Spline+Sans:wght@300;400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Kaushan Script", cursive;
}

img {
  width: 100px;
  height: 100px;
}

html {
  scroll-behavior: smooth;
}

#root {
  background: #000000;
  color: white;
}
#root .app-layout {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6901960784), rgba(255, 89, 0, 0.2980392157), rgba(255, 174, 0, 0.2980392157));
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}/*# sourceMappingURL=styles.css.map */